@font-face {
  font-family: "open_sansbold";
  src: url("./fonts/OpenSans/OpenSans-Bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sansregular";
  src: url("./fonts/OpenSans/OpenSans-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sanssemibold";
  src: url("./fonts/OpenSans/OpenSans-Semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "oswaldbold";
  src: url("./fonts/Oswald/Oswald-Bold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "oswaldregular";
  src: url("./fonts/Oswald/Oswald-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "oswaldsemibold";
  src: url("./fonts/Oswald/Oswald-SemiBold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}