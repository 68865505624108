// this file should have all the color set by name, so other scss file might reference those colors name
$chathams-blue: rgba(21, 75, 126, 1);
$azure-radiance: rgba(9, 139, 235, 1);
$blue-cola: rgba(12, 139, 235, 1);
$ocean-boat-blue: rgba(0, 121, 193, 1);
$science-blue: rgba(11, 58, 222, 1);
$blue: rgba(0, 0, 255, 1);
$palatinate-blue: rgba(11, 58, 222, 1);
$mine-shaft: rgba(51, 51, 51, 1);
$mine-shaft-light: rgba(36, 36, 36, 1);
$mirage: rgba(30, 35, 54, 1);
$black: rgba(0, 0, 0, 1);
$dark-charcoal: rgba(50, 50, 50, 1);
$dark-silver: rgba(112, 112, 112, 1);
$ebony-clay: rgba(47, 53, 74, 1);
$emperor: rgba(84, 83, 83, 1);
$granite-gray: rgba(100, 100, 100, 1);
$gray: rgba(128, 128, 128, 1);
$spanish-gray: rgba(154, 154, 154, 1);
$philippine-silver: rgba(180, 180, 180, 1);
$jewel: rgba(21, 126, 72, 1);
$green-haze: rgba(0, 171, 62, 1);
$japanese-laurel: rgba(0, 128, 0, 1);
$green-pantone: rgba(5, 169, 63, 1);
$light-green: rgba(144, 238, 144, 1);
$corn: rgba(235, 201, 12, 1);
$yellow: rgba(255, 255, 0, 1);
$sunglow: rgba(255, 192, 57, 1);
$silver: rgba(195, 195, 195, 1);
$silver-chalice: rgba(173, 173, 173, 1);
$wild-sand: rgba(244, 244, 244, 1);
$white: rgba(255, 255, 255, 1);
$alto: rgba(211, 211, 211, 1);
$alabaster: rgba(248, 248, 248, 1);
$alto-white: rgba(209, 209, 209, 1);
$platinum: rgba(228, 228, 228, 1);
$lotion: rgba(250, 250, 250, 1);
$gainsboro: rgba(227, 219, 219, 0.87);
$falu-red: rgba(126, 21, 22, 1);
$monza: rgba(217, 22, 10, 1);
$red: rgba(255, 0, 0, 1);
$scarlet: rgba(255, 51, 0, 1);