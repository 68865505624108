/* Main styles */
@import url("https://fonts.googleapis.com/css?family=Exo:500");
@import "./config/theme/_colors";

iframe {
  display: none;
}

.atlwdg-popup {
  iframe {
    display: flex;
  }
}

$breakpoints: (
  "small": 767px,
  "medium": 992px,
  "large": 1200px,
);

$midnight-color-base: $mirage;
$off-white-color-base: $wild-sand;
$light-navy-color-base: $ebony-clay;

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value !=null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;

  div[data-testid="side-bar"] {
    z-index: 1501 !important;
  }

  .tab-panel {
    border-radius: 15px;
    padding: 1rem;
    flex: 1 1 auto; /* Make the first child grow to fill the available height */
    overflow: auto;
    height: 100%;

    &_grow {
      & .MuiBox-root:first-child {
        height: calc(100% - 3rem);
      }
    }
  }

  .light-mode {
    .menu-button-stand-alone {
      svg {
        fill: $black;
        color: $black;
        line {
          stroke: $black;
        }
      }
    }

    .tab-panel {
      background-color: $white;
    }
  }

  .dark-mode {
    .menu-button-stand-alone {
      svg {
        fill: $white;
        color: $white;
        line {
          stroke: $white;
        }
      }
    }

    .tab-panel {
      background-color: $ebony-clay;
    }
  }

  .ufo-mode {
    .menu-button-stand-alone {
      svg {
        fill: $green-haze;
        color: $green-haze;
        line {
          stroke: $green-haze;
        }
      }
    }
  }
}

/**
 * Grid layout for all breakpoints
 */
.grid-container {
  align-content: start;
  display: grid;
  grid-column-gap: 0;
  height: 100%;
}

#root {
  .dark-mode {
    background-color: $midnight-color-base;
  }

  .light-mode {
    background-color: $off-white-color-base;
  }

  div[class$="-so-wrapper"] {
    padding: 1rem;
    width: 100%;

    @include respond-to("large") {
      padding: 2rem;
    }
  }

  div[class$="-so-dialog"] {
    .MuiToolbar-root {
      justify-content: space-between;
    }
  }
}

.App {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }

  .print {
    display: none;
  }

  @media print {
    .print {
      display: block;
    }

    .no-print {
      display: none;
    }
  }

  .App-header {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  main {
    min-height: 80vh;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        display: inline-block;
        margin: 20px;

        a {
          color: $black;
          text-decoration: none;

          &:hover,
          &:active {
            text-decoration: underline;
            color: $silver;
          }
        }
      }
    }
  }
}

.custom-tooltip .MuiTooltip-tooltip {
  font-size: 14px;
  color: $blue;
  background-color: $alabaster;
}

html {
  &[dir="ltr"] {
    .MuiDrawer-paperAnchorLeft {
      margin-left: 45px;
    }
  }
  &[dir="rtl"] {
    .MuiDrawer-paperAnchorLeft {
      margin-right: 45px;
    }
  }
}

.sideBar-Menu {
  padding-right: 15px;
}
.subheading {
  font-size: 14px;
  font-family: "open_sansbold";
  color: $silver;
  margin-left: 33px;
}
.mainBody-route {
  height: 100%;
  position: relative;
}

html {
  body {
    .MuiMenu-root {
      &.MuiModal-root {
        z-index: 1501;
      }
    }
  }
}

/* @TODO remove once JIRA issues button removed */
html {
  body {
    .MuiAutocomplete-popper, .data-patcher-dialog {
      z-index: 1302 !important;
    }
  }

  #atlwdg-trigger {
    z-index: 99999;
    top: unset;
    display: flex;
    overflow: hidden;
    width: 8rem;
    text-align: center;
    text-indent: 0;
    margin: 0;
    height: 2rem;
    position: fixed !important;
    padding: 0;
    box-sizing: unset;
    left: unset;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
  }

  &[dir="ltr"] {
    #atlwdg-trigger {
      bottom: -1rem;
      left: 0rem;
      transform: rotate(270deg);
    }
  }

  &[dir="rtl"] {
    #atlwdg-trigger {
      bottom: 7rem;
      right: -8rem;
      transform: rotate(90deg);
    }
  }
}

// if the screen height is less than 700px, hide the #atlwdg-trigger button
@media screen and (max-height: 750px) {
  #atlwdg-trigger {
    display: none !important;
  }
}
